import React, { useEffect, useState } from 'react';
import PlayerComponent from './Pages/Player';
import DuplicatePage from './Pages/DuplicatePage';
import Auth from './Pages/login/Auth';
function App() {

  const [gameViewStatus, setGameViewOn] = useState(true);


  useEffect(() => {
    if (localStorage.getItem('web_browser') == null) {
      // new tab
      localStorage.setItem('web_browser', 'true');
      window.addEventListener('unload', function () {
        localStorage.removeItem('web_browser');
        localStorage.removeItem('token');
      })
    } else {
      setGameViewOn(false);
      return;
    }
  }, []);



  return (

    gameViewStatus ? <PlayerComponent /> : <DuplicatePage />

  )
}


const WithAuth = () => {

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setLoggedIn(!true);
    // let token = localStorage.getItem("token");
    // if(token && token.length > 20) {
    //   setLoggedIn(true)
    // } else {
    //   setLoggedIn(false);
    // }
  }, []);

  return (
    loggedIn ? <App /> : <Auth setLoggedIn={setLoggedIn} />
  )
}



export default WithAuth;
