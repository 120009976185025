import React, { useEffect, useState } from 'react';
import { login } from './LoginServices';
import Loader from './../Loader';

function Login(props) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loaderMsg, setLoaderMessage] = useState(undefined);
    const [errorMsg, setErrorMessage] = useState("");

    const validateEmail = () => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const loginButtonClick = () => {

        startLoading("Verifying user credentails...");
        let loginParams = {
            'email': email,
            'password': password
        }

        login(loginParams).then(res => {
            if (res.status == 200) {
                if (res.data && res.data.success) {
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("userId", new Date().getDate()+""+res.data.user.id);
                    props.setLoggedIn(true);
                }

            } else {
                //setInvalidLogin(true);
                setErrorMessage(res.data.message);
            }
            stopLoading(res.data.message);
        }).catch(er => {
            console.log(er)
            setErrorMessage(er.response.data.message);
            stopLoading();
        })
    }

    const startLoading = (msg) => {
        setLoaderMessage(msg);
        setIsLoading(true);
    }

    const stopLoading = (msg) => {
        msg ? setLoaderMessage(msg) : setLoaderMessage(undefined);
        setIsLoading(false);
    }

    

    return (
        <>
            {isLoading ? <Loader message={loaderMsg} /> :
                <div className='min-h-screen bg'>
                    <div className='overlay_bg' style={{ backgroundColor: '#ffffff10' }}>
                        <div id="outer">
                            <div id="inner">
                                <img src='../logo.png' />
                            </div>
                        </div>

                        <div class="min-h-screen flex flex-col justify-center relative overflow-hidden sm:py-12">
                            <div class="box" style={{ backgroundColor: '#33342a75' }}>
                                <input type="email" onChange={(e) => setEmail(e.target.value)} className='input_bg'
                                    value={email} style={{ backgroundColor: '#96998e' }} placeholder="Email" />
                                <input type="password" onChange={(e) => setPassword(e.target.value)} className='input_bg'
                                    value={password} style={{ backgroundColor: '#96998e' }} placeholder="Password" />
                                {/* <input type="button" onClick={loginButtonClick} className='bg-blue-500 text-white font-bold py-2 px-4 border rounded' value="Login Now" /> */}
                                <button onClick={loginButtonClick} disabled={!validateEmail() || password.length == 0}
                                    className={`${!validateEmail() || password.length < 5 ? 'bg-gray-500' : 'bg-blue-500'} text-white font-bold py-2 px-4 border rounded`} >Login Now</button>
                                <p style={{ color: 'white', textAlign: 'center', padding: '10px' }}>{errorMsg}</p>
                                {/* <p>New user click, <a onClick={() => props.setAuthpage("REGISTER")} >Register here</a></p> */}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default Login
