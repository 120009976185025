import axios from 'axios';
import React, { useEffect, useState } from 'react';


function Register(props) {

    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");

    const validateEmail = () => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const registerButtonClick = () => {
        let data = {
            name, username, email, phone, password
        }
        axios.post("http://localhost:5000/user/register", data)
        .then(res => {
            alert("User registered successfully!");
            props.setAuthpage("LOGIN");
        }).catch(err => {
            alert(err.message)
        })
    }

    return (
        <>
            <div className='container min-h-screen bg'>
                <div className='overlay_bg' style={{ backgroundColor: '#ffffff10' }}>
                    <div id="outer">
                        <div id="inner">
                            <img src='../logo.png' />
                        </div>
                    </div>

                    <div class="min-h-screen flex flex-col justify-center relative overflow-hidden sm:py-12">
                        <div class="box" style={{ backgroundColor: '#33342a75' }}>
                            <input type="text" onChange={(e) => setName(e.target.value)} className='input_bg'
                                value={name} style={{ backgroundColor: '#96998e' }} placeholder="Name" />
                            <input type="username" onChange={(e) => setUsername(e.target.value)} className='input_bg'
                                value={username} style={{ backgroundColor: '#96998e' }} placeholder="User name" />
                            <input type="email" onChange={(e) => setEmail(e.target.value)} className='input_bg'
                                value={email} style={{ backgroundColor: '#96998e' }} placeholder="Email" />
                            <input type="text" onChange={(e) => setPhone(e.target.value)} className='input_bg'
                                value={phone} style={{ backgroundColor: '#96998e' }} placeholder="Phone number" />
                            <input type="password" onChange={(e) => setPassword(e.target.value)} className='input_bg'
                                value={password} style={{ backgroundColor: '#96998e' }} placeholder="Password" />
                            {/* <input type="button" onClick={loginButtonClick} className='bg-blue-500 text-white font-bold py-2 px-4 border rounded' value="Login Now" /> */}
                            <button onClick={registerButtonClick} disabled={!validateEmail() || password.length == 0}
                                className={`${!validateEmail() || password.length < 5 ? 'bg-gray-500' : 'bg-blue-500'} text-white font-bold py-2 px-4 border rounded`} >Register Now</button>
                        <p>Already a user, <a onClick={() => props.setAuthpage("LOGIN")} >Login here</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Register;
