import React, { Component } from 'react';
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import loadUtils from "../Utils/app";
import Loader from './Loader';
import '../App.css';

export default class DuplicatePage extends Component {

    render() {

        return (

            <>
                <div className='container content-center'>
                    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-800">
                        <div className="container flex flex-wrap justify-between items-center mx-auto">
                            <a href="https://www.interverse.ai/" className="flex items-center">
                                <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Creator Studio</span>
                            </a>
                        </div>
                    </nav>


                    <div className="grid place-items-center" style={{height:'70vh'}}>
                        <div className="inline-block align-text-top bg-blue-100 border-t border-b border-blue-500 px-4 py-3" role="alert">
                            <p className="font-bold">Informational message</p>
                            <p className="text-sm">Another tab is open please close it to enjoy new tab smoothly</p>
                        </div>

                    </div>
                </div >
            </>



        )
    }
}

