import React, { useState } from "react";
import Login from "./Login";
import Register from "./Register";
import { setBaseUrl } from "../../Services/HttpService"

export default function Auth(props) {

    const [authpage, setAuthpage] = useState("LOGIN");

    React.useEffect(() => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        let init = {
          method: "GET",
          headers: headers,
        };
        fetch("/appConfig.json", init)
          .then((response) => {
            return response.json();
          })
          .then((obj) => {
            setBaseUrl(obj.baseUrl);
          });
      }, []);

    return (
        authpage == "LOGIN" ? 
        <Login {...props} setAuthpage={setAuthpage} /> : <Register {...props} setAuthpage={setAuthpage} />
    )
}   