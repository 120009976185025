import React, { Component } from 'react';
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import loadUtils from "../Utils/app";
import Loader from './Loader';
import '../App.css';




const speechConfig = sdk.SpeechConfig.fromSubscription("444b0b8cf69d40168fe8fa4b187f7ac0", "eastus");
const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

let isSpaceBarDown = false;

export default class PlayerComponent extends Component {

    constructor() {
        super();

        this.state = {
            downloadBtnStatus: false,
            gameStarted: false,
            reload: false,
            recognizer: new sdk.SpeechRecognizer(speechConfig, audioConfig),
            isRecognizing: false,
            selectedResolution: "INSTA_REEL",
            sample: true
        }
    }


    componentDidMount = () => {
        loadUtils(this.eventsCallbackHandler);
        //this.setKeyDownEventsForSTT(); //commented we handling key events in app.js

    }

    setKeyDownEventsForSTT = () => {

        let scope = this;
        window.document.body.onkeydown = function (e) {
            if (e.code == "Space" && !isSpaceBarDown) {
                console.log("DOWN DOWN DOWN ");
                isSpaceBarDown = true;
                scope.startSTT();
            }
        }

        window.document.body.onkeyup = function (e) {
            if (e.code == "Space" && isSpaceBarDown) {
                isSpaceBarDown = false;
                scope.stopSTT();
            }
        }
    }


    eventsCallbackHandler = (event) => {
        switch (event) {
            // case "StartGame":
            //     console.log(" ********** Start Game **********");
            //     this.setState({
            //         gameStarted: true,
            //         reload: false
            //     });
            //     this.updateRecognizer();
            //     break;
            // case "resetGame":
            //     console.log(" ********** Reset Game **********");
            //     this.setState({
            //         gameStarted: false,
            //         reload: true
            //     });
            //     this.updateRecognizer();
            //     break;
            case "StartRecognition":
                console.log(" ********** StartRecognition **********");
                this.startSTT();
                break;
            case "StopRecognition":
                console.log(" ********** StopRecognition **********");
                this.stopSTT();
                break;
            case "close":
                console.log(" ********** Close **********");
                this.updateRecognizer();
                break;
            case "video_available":
                console.log(".........video present.....");
                let videoDataJson = window.videoData;
                this.setState({
                    downloadBtnStatus: true
                })
                break;
            case "video_reset":
                console.log(".........video.....");
                this.setState({ downloadBtnStatus: false })
                break;

        }
    }

    updateRecognizer = () => {
        if (this.state.isRecognizing) {
            this.stopSTT();
        }
    }


    reset = () => {
        try {

            this.setState({
                downloadBtnStatus: false
            })

            /*  let data = {
                 resetGame: "To initial state"
             }
             window.emitUIInteraction(data);
             // this.setState({
             //     gameStarted: false,
             //     reload: true
             // });
             // this.updateRecognizer(); */
        } catch (e) {
            console.log("emitUIInteraction error: ", e);
        }
    }

    startSTT = () => {
        console.log("Starting STT ....... ");
        try {

            this.state.recognizer.recognizing = (s, e) => {
                console.log(`RECOGNIZING: Text=${e.result.text}`);
            };

            this.state.recognizer.recognized = (s, e) => {
                if (e.result.reason == sdk.ResultReason.RecognizedSpeech) {
                    console.log(`RECOGNIZED: Text=${e.result.text}`);
                    try {
                        let recognisedText = e.result.text.toLowerCase();
                        recognisedText = recognisedText.replace(/[^a-zA-Z0-9 ]/g, '').trim();
                        let data = {
                            type:"STT",
                            transcript: recognisedText
                        }
                        console.log(`Text to send =${recognisedText}`);
                        window.emitUIInteraction(data);
                        this.stopSTT();
                    } catch (emitUIInteractionErr) {
                        console.log("************* emitUIInteraction error **********", emitUIInteractionErr);
                    }
                }
                else if (e.result.reason == sdk.ResultReason.NoMatch) {
                    console.log("NOMATCH: Speech could not be recognized.");
                }
            };

            this.state.recognizer.canceled = (s, e) => {
                console.log(`CANCELED: Reason=${e.reason}`);

                if (e.reason == sdk.CancellationReason.Error) {
                    console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                    console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                    console.log("CANCELED: Did you update the key and location/region info?");
                }

                this.state.recognizer.stopContinuousRecognitionAsync();
                this.setState({
                    isRecognizing: false
                });
            };

            this.state.recognizer.sessionStopped = (s, e) => {
                console.log("\n    Session stopped event.");
                this.state.recognizer.stopContinuousRecognitionAsync();
                this.setState({
                    isRecognizing: false
                });
            };

            this.state.recognizer.sessionStarted = (s, e) => {
                console.log("\n    Session start event.");
                this.setState({
                    isRecognizing: true
                });
            }

            this.state.recognizer.startContinuousRecognitionAsync();

        } catch (startSTTErr) {
            console.log("************ Error at startSTT function **********");
            console.log(startSTTErr);
        }
    }

    stopSTT = () => {
        console.log("Stop STT ..........");
        try {
            if (this.state.isRecognizing) {
                this.state.recognizer.stopContinuousRecognitionAsync();
                this.setState({
                    isRecognizing: false
                });
            }
        } catch (stopSTTErr) {
            console.log("******* stopSTTErr **********", stopSTTErr);
        }
    }

    onDownloadClick = () => {

        window._wsocket.send(JSON.stringify({
            type: 'video_input',
            data: window.videoData,
            resolution: this.state.selectedResolution
        }))

    }


    onResolutionChange = (e) => {
        this.setState({ selectedResolution: e.target.value });
    }


    render() {

        return (
            <div>
                {this.state.reload && <Loader />}
                <div id="playerUI">
                    <div id="player"></div>

                    <div id="overlay" className="overlay text-light bg-dark" style={{ display: 'none' }} >
                        <div className='flex flex-row p-1'>
                            <div id="qualityStatus" className="greyStatus w-4 h-4 bg-white rounded-full mt-1"></div>
                            <div id="overlayButton" className='font-bold text-lg ml-2' >+</div>
                        </div>
                        <div id="overlaySettings">
                            <div id="kickOthers">
                                <div className="settings-text">Kick all other players</div>
                                <label className="btn-overlay">
                                    <input type="button" id="kick-other-players-button" className="overlay-button btn-flat" value="Kick" />
                                </label>
                            </div>
                            <div id="fillWindow">
                                <div className="settings-text">Enlarge Display to Fill Window</div>
                                <label className="tgl-switch">
                                    <input type="checkbox" id="enlarge-display-to-fill-window-tgl" className="tgl tgl-flat" checked />
                                    <div className="tgl-slider"></div>
                                </label>
                            </div>
                            <div id="qualityControlOwnership">
                                <div className="settings-text">Quality control ownership</div>
                                <label className="tgl-switch">
                                    <input type="checkbox" id="quality-control-ownership-tgl" className="tgl tgl-flat" />
                                    <div className="tgl-slider"></div>
                                </label>
                            </div>
                            <br />
                            <section id="encoderSettings">
                                <div className="settings-text">Encoder Settings</div>
                                <div id="encoderParamsContainer" className="collapse">
                                    <div className="form-group">
                                        <label for="encoder-rate-control" className="settings-text">Rate Control</label>
                                        <select id="encoder-rate-control">
                                            <option value="CBR" selected>CBR</option>
                                            <option value="VBR">VBR</option>
                                            <option value="ConstQP">ConstQP</option>
                                        </select>
                                        <label for="encoder-target-bitrate-text">Target Bitrate (kbps)</label>
                                        <input type="number" className="form-control" id="encoder-target-bitrate-text" value="0" min="0" max="100000" />
                                        <label for="encoder-max-bitrate-text">Max Bitrate (kbps)</label>
                                        <input type="number" className="form-control" id="encoder-max-bitrate-text" value="0" min="0" max="100000" />
                                        <label for="encoder-min-qp-text">Min QP</label>
                                        <input type="number" className="form-control" id="encoder-min-qp-text" value="0" min="0" max="999" />
                                        <label for="encoder-max-qp-text">Max QP</label>
                                        <input type="number" className="form-control" id="encoder-max-qp-text" value="0" min="0" max="999" />
                                        <label for="encoder-multipass" className="settings-text">Multipass</label>
                                        <select id="encoder-multipass">
                                            <option value="DISABLED" selected>DISABLED</option>
                                            <option value="QUARTER">QUARTER</option>
                                            <option value="FULL">FULL</option>
                                        </select>
                                        <div className="settings-text">Filler Data</div>
                                        <label className="tgl-switch">
                                            <input type="checkbox" id="encoder-filler-data-tgl" className="tgl tgl-flat" />
                                            <div className="tgl-slider"></div>
                                        </label>
                                    </div>
                                    <input id="encoder-params-submit" className="btn btn-primary btn-lg mt-3" type="button" value="Apply" />
                                </div>
                                <br />
                            </section>
                            <section id="webRTCSettings">
                                <div className="settings-text">WebRTC Settings</div>
                                <div id="webrtcParamsContainer" className="collapse">
                                    <div className="form-group">
                                        <label for="webrtc-degradation-pref">Degradation Pref</label>
                                        <select id="webrtc-degradation-pref">
                                            <option value="BALANCED">BALANCED</option>
                                            <option value="MAINTAIN_FRAMERATE">MAINTAIN_FRAMERATE</option>
                                            <option value="MAINTAIN_RESOLUTION">MAINTAIN_RESOLUTION</option>
                                        </select>
                                        <label for="webrtc-max-fps-text">Max FPS</label>
                                        <input type="number" className="form-control" id="webrtc-max-fps-text" value="1" min="1" max="999" />
                                        <label for="webrtc-min-bitrate-text">Min Bitrate (kbps)</label>
                                        <input type="number" className="form-control" id="webrtc-min-bitrate-text" value="0" min="0" max="100000" />
                                        <label for="webrtc-max-bitrate-text">Max Bitrate (kbps)</label>
                                        <input type="number" className="form-control" id="webrtc-max-bitrate-text" value="0" min="0" max="100000" />
                                        <label for="webrtc-low-qp-text">Low QP Threshold</label>
                                        <input type="number" className="form-control" id="webrtc-low-qp-text" value="0" min="0" max="999" />
                                        <label for="webrtc-high-qp-text">High QP Threshold</label>
                                        <input type="number" className="form-control" id="webrtc-high-qp-text" value="0" min="0" max="999" />
                                    </div>
                                    <input id="webrtc-params-submit" className="btn btn-primary btn-lg mt-3" type="button" value="Apply" />
                                </div>
                            </section>
                            <br />
                            <div id="showFPS">
                                <div className="settings-text">Show FPS</div>
                                <label className="btn-overlay">
                                    <input type="button" id="show-fps-button" className="overlay-button btn-flat" value="Toggle" />
                                </label>
                            </div>
                            <div id="matchViewportResolution">
                                <div className="settings-text">Match Viewport Resolution</div>
                                <label className="tgl-switch">
                                    <input type="checkbox" id="match-viewport-res-tgl" className="tgl tgl-flat" />
                                    <div className="tgl-slider"></div>
                                </label>
                            </div>
                            <div id="statsPanel">
                                <div className="settings-text">Show Stats</div>
                                <label className="tgl-switch">
                                    <input type="checkbox" id="show-stats-tgl" className="tgl tgl-flat" checked />
                                    <div className="tgl-slider"></div>
                                </label>
                                <div id="statsContainer" className="statsContainer">
                                    <div id="stats" className="stats"></div>
                                </div>
                                <br />
                            </div>
                            <div id="latencyTest">
                                <div className="settings-text">Latency Stats</div>
                                <label className="btn-overlay">
                                    <input type="button" id="test-latency-button" className="overlay-button btn-flat" value="Test Latency" />
                                </label>
                                <div id="latencyStatsContainer" className="statsContainer">
                                    <div id="LatencyStats" className="stats">No stats yet...</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

