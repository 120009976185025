import React from 'react'

export default function Loader(props) {



    return (
        <div className=' flex flex-row justify-center opacity-50 w-screen h-screen z-30' >
            <div className='absolute top-1/2'>
                <div className="animate-pulse p-3 flex flex-row justify-between bg-gradient-to-t rounded-lg shadow-sm">
                    <div className='animate-spin w-8 h-8 border-t-2 border-gary-100 rounded-full' ></div> 
                    <div className=' ml-3 m-1 text-md font-semibold text-gray-50' > <i> {props.message ? props.message : " Reloading initial scene..." }</i> </div>
                </div>
            </div>
        </div>
    )
}
