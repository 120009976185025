/**
 * API and Render url defined
 */
 export const RENDER_URL = {
    HOME_URL: '/home',
    LOGIN_URL: "/login",

};


export const API_URL = {
    LOGIN: "/labs/user/login",
}
